import React from 'react'
import { useField } from 'formik'
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
} from '@chakra-ui/react'
import { FormLabel } from './FormLabel'
import { useBorderColor } from './hooks/useBorderColor'

export type TextFieldProps = {
  label?: string
  name: string
  type?: string
  placeholder?: string
  inputLeftElement?: React.ReactNode
  inputRightElement?: React.ReactNode
  InputProps?: InputProps
} & FormControlProps

export const TextField = ({
  label,
  name,
  type,
  placeholder,
  inputLeftElement,
  inputRightElement,
  InputProps,
  ...props
}: TextFieldProps) => {
  const [field, meta] = useField(name)
  const borderColor = useBorderColor(field)
  return (
    <FormControl isInvalid={!!meta.error && meta.touched} {...props}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <InputGroup>
        {inputLeftElement && <InputLeftElement children={inputLeftElement} h="100%" />}
        <Input
          id={name}
          type={type}
          placeholder={placeholder}
          fontSize="sm"
          borderRadius={0}
          borderColor={borderColor}
          _hover={{
            borderColor: borderColor,
          }}
          pt={type?.includes('datetime') ? '14px' : '22px'}
          pb={type?.includes('datetime') ? '34px' : '22px'}
          {...field}
          {...InputProps}
        />
        {inputRightElement && <InputRightElement children={inputRightElement} h="100%" />}
      </InputGroup>
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  )
}
