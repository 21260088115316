import React, { useEffect, useState } from 'react'
import FlatfileImporter from 'flatfile-csv-importer'
import { FlatfileResults, getImporter } from 'libs/flatfile'
import { useNotify, useTranslations } from 'hooks'
import translations from './BulkImportButton.i18n.json'
import { ActionButton } from 'components'

type Props = {
  fields?: object
  onImport: (results: FlatfileResults<any>) => Promise<any>
}

export const BulkImportButton = ({ fields, onImport }: Props) => {
  const [importer, setImporter] = useState<FlatfileImporter>()
  const [isShowingImporter, setIsShowingImporter] = useState(false)
  const [isImporting, setIsImporting] = useState(false)
  const notify = useNotify()
  const t = useTranslations(translations)

  useEffect(() => {
    if (fields) {
      setImporter(getImporter(fields))
    }
  }, [fields])

  async function showImporter() {
    setIsShowingImporter(true)
    if (importer) {
      try {
        const result = await importer.requestDataFromUser()
        importer.close()
        setIsImporting(true)
        try {
          await onImport(result)
        } finally {
          setIsImporting(false)
        }
      } catch (error) {
        if (error) {
          notify.error(error)
        } else {
          // close event, do nothing
        }
      } finally {
        setIsShowingImporter(false)
      }
    }
  }

  const isDisabled = !importer

  return (
    <ActionButton onClick={showImporter} isDisabled={isDisabled} isLoading={isShowingImporter || isImporting}>
      {t.import}
    </ActionButton>
  )
}
