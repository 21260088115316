import { API } from 'aws-amplify'
import { TransitEvent } from './authorizationEvents'
import { DeviceItem } from './devices'
import { ListResponse } from './types'
import { Zone } from './zones'

export type DeviceGroupDevice = Pick<DeviceItem, 'thing_id' | 'name'> & { direction: DeviceDirection }

export enum HumanFriendlyDeviceGroupStatus {
  free_transit = 'Free Transit',
  secure = 'Secure',
  locked = 'Locked Down',
}

export enum DeviceGroupStatus {
  FreeTransit = 'free_transit',
  Secure = 'secure',
  Locked = 'locked',
}

export type DeviceGroup = {
  device_group_id: string
  created_at: string
  name: string
  zone_id?: string | null
  updated_at: string
  zone?: Zone
  devices: DeviceGroupDevice[]
  last_transit_event?: TransitEvent | null
  managing_device_id?: string | null
  managing_device?: DeviceItem | null
  device_group_type: 'lane' | 'door'
  status: DeviceGroupStatus
}

export type DeviceGroupFormValues = Pick<DeviceGroup, 'name' | 'zone_id' | 'device_group_type'>

export type CreateDeviceGroupPayload = DeviceGroupFormValues

export type DeviceGroupFilterParams = {
  with_last_transit_event?: boolean
  zone_id?: string[]
}

export async function getDeviceGroupsList(): Promise<ListResponse<DeviceGroup>> {
  return API.get('device-groups', '/device-groups', {
    queryStringParameters: {
      responseFormat: 'list',
    },
  })
}

export async function getDeviceGroups(params: DeviceGroupFilterParams): Promise<DeviceGroup[]> {
  return API.get('device-groups', '/device-groups', { queryStringParameters: params })
}

export async function fetchDeviceGroup({ id }: { id: number | string }): Promise<DeviceGroup> {
  return API.get('device-groups', `/device-groups/${id}`, {})
}

export const deleteDeviceGroup = async (deviceGroup: DeviceGroup): Promise<void> => {
  return API.del('device-groups', `/device-groups/${deviceGroup.device_group_id}`, {})
}

export async function createDeviceGroup(body: CreateDeviceGroupPayload): Promise<DeviceGroup> {
  return API.post('device-groups', `/device-groups`, { body })
}

export async function updateDeviceGroup(deviceGroup: DeviceGroup, payload: Partial<DeviceGroup>): Promise<DeviceGroup> {
  return API.put('device-groups', `/device-groups/${deviceGroup.device_group_id}`, { body: payload })
}

export type DeviceDirection = 'secure' | 'unsecure' | 'both'

export type updateDeviceGroupDevicesPayload = {
  devices: Array<{ device_id: string; direction: DeviceDirection }>
}

export async function updateDeviceGroupDevices(
  deviceGroupId: string,
  body: updateDeviceGroupDevicesPayload
): Promise<any> {
  return API.put('device-groups', `/device-groups/${deviceGroupId}/devices`, { body })
}

export async function lockDoor(deviceGroupId: string): Promise<void> {
  return updateDeviceGroupStatus(deviceGroupId, DeviceGroupStatus.Locked)
}
export async function unlockDoor(deviceGroupId: string): Promise<void> {
  return updateDeviceGroupStatus(deviceGroupId, DeviceGroupStatus.FreeTransit)
}
export async function secureDoor(deviceGroupId: string): Promise<void> {
  return updateDeviceGroupStatus(deviceGroupId, DeviceGroupStatus.Secure)
}

function updateDeviceGroupStatus(deviceGroupId: string, status: DeviceGroupStatus) {
  return API.put('device-groups', `/device-groups/${deviceGroupId}`, {
    body: {
      status,
    },
  })
}
function bulkUpdateZoneStatus(zoneId: string, status: DeviceGroupStatus) {
  return API.patch('device-groups', '/device-groups', {
    body: {
      zone_id: zoneId,
      status,
    },
  })
}

export async function lockZone(zoneId: string) {
  return bulkUpdateZoneStatus(zoneId, DeviceGroupStatus.Locked)
}

export async function secureZone(zoneId: string) {
  return bulkUpdateZoneStatus(zoneId, DeviceGroupStatus.Secure)
}

export async function unlockZone(zoneId: string) {
  return bulkUpdateZoneStatus(zoneId, DeviceGroupStatus.FreeTransit)
}
