import React, { createContext, useContext, useState } from 'react'

type Locale = 'en-US' | 'es-CL' // Add more locales as needed

interface LocaleContextType {
  locale: Locale
  setLocale: (locale: Locale) => void
}

const LocaleContext = createContext<LocaleContextType | undefined>(undefined)

export const LocaleProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const savedLocale = (localStorage.getItem('locale') as Locale) || 'en-US' // Default to English
  const [locale, setLocale] = useState<Locale>(savedLocale)

  const updateLocale = (newLocale: Locale) => {
    setLocale(newLocale)
    localStorage.setItem('locale', newLocale)
  }

  return <LocaleContext.Provider value={{ locale, setLocale: updateLocale }}>{children}</LocaleContext.Provider>
}

export const useLocale = (): LocaleContextType => {
  const context = useContext(LocaleContext)
  if (!context) {
    throw new Error('useLocale must be used within a LocaleProvider')
  }
  return context
}
