import React, { ReactNode } from 'react'
import './index.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ChakraProvider, ThemeProvider } from '@chakra-ui/react'
import theme from './theme'
import { IntlProvider } from 'react-intl'
import enMessages from 'translations/en-US.json'
import esMessages from 'translations/es-CL.json'
import { QueryClientProvider } from 'react-query'
import { LastLocationProvider } from 'components/LastLocationProvider'
import { HealthModeProvider } from 'components/HealthModeProvider'
import { queryClient } from 'hooks/useQuery'
import { LocaleProvider, useLocale } from 'LocaleProvider'

export const history = createBrowserHistory()

export function AppProvider({ children, noGlobalStyle }: { children: ReactNode; noGlobalStyle?: boolean }) {
  const ChakraThemeProvider = noGlobalStyle ? ThemeProvider : ChakraProvider

  return (
    <React.StrictMode>
      <LocaleProvider>
        <IntlProviderWrapper>
          <HealthModeProvider>
            <ChakraThemeProvider resetCSS theme={theme}>
              <QueryClientProvider client={queryClient}>
                <Router history={history}>
                  <LastLocationProvider>{children}</LastLocationProvider>
                </Router>
              </QueryClientProvider>
            </ChakraThemeProvider>
          </HealthModeProvider>
        </IntlProviderWrapper>
      </LocaleProvider>
    </React.StrictMode>
  )
}

const IntlProviderWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { locale } = useLocale()

  const messages = {
    'en-US': enMessages,
    'es-CL': esMessages,
  }

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  )
}
